
<div class="container-fluid content-top-gap">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <div *ngIf="user?.authStatus == 'AUTH'" class="welcome-msg pt-3 pb-4">
            <h1>Hi {{user?.name}}, Welcome back!</h1>
        </div>
    </div>

    <!-- Filters in the same row -->
    <div class="filters mb-4 d-flex align-items-center">
        <!-- Event Description Filter -->
        <div class="form-group me-3 flex-grow-1" (scroll)="onDropdownScroll($event)"
            style="max-height: 200px; overflow-y: auto;">
            <label for="eventDescription">Filter by Event:</label>
            <select id="eventDescription" class="form-control" [(ngModel)]="selectedEventId"
                (change)="onFilterChange()">
                <option value="">All Events</option>
                <option *ngFor="let event of events" [value]="event.bid">{{ event.prizeDetails }}</option>
            </select>
            <div *ngIf="eventsLoading" class="text-center mt-2">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <p>Loading more events...</p>
            </div>
        </div>

        <!-- Year Filter -->
        <div class="form-group flex-grow-1">
            <label for="yearFilter">Filter by Year:</label>
            <select id="yearFilter" class="form-control" [(ngModel)]="selectedYear" (change)="onFilterChange()">
                <option value="">All Years</option>
                <option *ngFor="let year of yearsList" [value]="year">{{ year }}</option>
            </select>
        </div>
    </div>

    <div class="container scrollable-container" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()">
        <h1 class="vip-title">Order history</h1>
        <div class="events-grid">
            <div class="event-card" *ngFor="let order of orders">
                <img [src]="order.event.prizeImage" alt="Event Thumbnail" class="thumbnail">
                <div class="card-content">
                    <h2 class="event-title" [title]="order.event.prizeDetails">{{ order.event.prizeDetails }}</h2>
                    <div class="event-info">
                        <div class="info-row">
                            <span class="info-label">Entry price:</span>
                            <span class="info-value">{{ order.event.prizeValue | currency:'PHP':'symbol' }}</span>
                        </div>
                        <div class="info-row">
                            <span class="info-label">Entry price:</span>
                            <span class="info-value">{{ order.event.entryPrice | currency:'PHP':'symbol' }}</span>
                        </div>
                        <div class="info-row">
                            <span class="info-label">Tickets:</span>
                            <span class="info-value">{{ order.tickets }}</span>
                        </div>
                        <div class="info-row">
                            <span class="info-label">Amount Paid:</span>
                            <span class="info-value">{{ order.amount | currency:'PHP':'symbol' }}</span>
                        </div>
                    </div>
                </div>
                <span class="info-label">Payment Id:</span>
                <div class="order-id">{{ order.bid}}</div>
                <div class="time-left">Order Date: {{ order.createdDate | date: 'MMM d, y, h:mm a' }}</div>
            </div>
        </div>
        
        <div *ngIf="loading" class="loading-spinner">
            <div class="spinner"></div>
            <p>Loading more orders...</p>
        </div>
    </div>
</div>
