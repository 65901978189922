<header class="site-header">
    <div class="header-content">
        <div class="logo">Yana</div>
        <input type="checkbox" id="nav-toggle">
        <label for="nav-toggle" class="nav-toggle-label" *ngIf="user.authStatus !== 'AUTH'">&#9776;</label>
        <label for="nav-toggle" class="nav-toggle-label" *ngIf="user.authStatus === 'AUTH'"><img [src]="user.picture" alt="Profile Picture"
                class="profile-pic" /></label>
        <nav class="nav-content">
            <ul>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="">Home</a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="/events">Events</a>
                </li>
                <li *ngIf="user.authStatus !== 'AUTH'" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a (click)="login()">Login</a>
                </li>
                <li *ngIf="user.authStatus !== 'AUTH'" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a (click)="register()">Register</a>
                </li>
                <li *ngIf="user.authStatus !== 'AUTH'" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="/contact">Contact US</a>
                </li>
                <li *ngIf="user.authStatus !== 'AUTH'" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="/notices">Notices</a>
                </li>
                <li *ngIf="user.authStatus === 'AUTH'" routerLinkActive="active">
                    <a routerLink="/orders">Orders</a>
                </li>
                <li *ngIf="user.authStatus === 'AUTH'" class="profile-dropdown">
                    <img [src]="user.picture" alt="Profile Picture" class="profile-pic" (click)="toggleDropdown()" />
                    <div class="dropdown-content " *ngIf="isDropdownOpen">
                        <a (click)="logout()">Logout</a>
                    </div>
                </li>
                <li *ngIf="user.authStatus === 'AUTH'" class="bigscreen-logout" routerLinkActive="active">
                    <a (click)="logout()">Logout</a>
                </li>
            </ul>
        </nav>
    </div>
</header>