
<div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <div *ngIf="user?.authStatus == 'AUTH'" class="welcome-msg pt-3 pb-4">
            <h1>Hi {{user?.name}}, Welcome back!</h1>
        </div>
    </div>
    <div class="container scrollable-container" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()">
        <h1 class="vip-title">All Events</h1>
        <div class="events-grid">
            <div class="event-card" *ngFor="let event of events">
                <img [src]="event.prizeImage" alt="Event Thumbnail" class="thumbnail">
                <div class="card-content">
                    <h2 class="event-title" [title]="event.prizeDetails">{{ event.prizeDetails }}</h2>
                    <div class="event-info">
                        <div class="info-row">
                            <span class="info-label">Prize:</span>
                            <span class="info-value">{{ event.prizeValue | currency:'PHP':'symbol' }}</span>
                        </div>
                        <div class="info-row">
                            <span class="info-label">Entry price:</span>
                            <span class="info-value">{{ event.entryPrice | currency:'PHP':'symbol' }}</span>
                        </div>
                    </div>
                    <div class="time-left" [ngClass]="{'urgent': isUrgent(event.entryCloseDate)}">
                        <ng-container *ngIf="!isEventClosed(event.entryCloseDate); else closedEvent">
                            Closes in: {{ getTimeRemaining(event.entryCloseDate) }}
                        </ng-container>
                        <ng-template #closedEvent>
                            <ng-container *ngIf="isPrizeDrawPending(event); else winnerAnnouncement">
                                <span class="pending-draw-message">Entry closed. Stay tuned for winner!</span>
                            </ng-container>
                            <ng-template #winnerAnnouncement>
                                <div class="winner-wrapper">
                                    <span *ngIf="event.winnerName" class="winner-message">
                                        Winner: <span class="winner-name">{{ event.winnerName }}</span>
                                        <div class="firework"></div>
                                        <div class="firework"></div>
                                        <div class="firework"></div>
                                    </span>
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>
                    <button *ngIf="!event.winnerName && !isEventClosed(event.entryCloseDate)" class="bet-button" (click)="onBuySubscription(event)">Buy</button>
                </div>
            </div>
        </div>
        <div *ngIf="loading" class="loading-spinner">
            <div class="spinner"></div>
            <p>Loading more events...</p>
        </div>
    </div>
</div>

<!-- Beautified Buy Subscription Modal -->
<div class="modal fade vip-modal" #buySubscriptionModal tabindex="-1" aria-labelledby="buySubscriptionModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="buySubscriptionModalLabel">VIP Event Subscription</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="subscriptionForm">
                    <div class="row mb-4">
                        <div class="col-md-6">
                            <img [src]="selectedEvent?.prizeImage" class="img-fluid rounded" alt="Prize Image" />
                        </div>
                        <div class="col-md-6">
                            <h3 class="vip-text mb-3">{{ selectedEvent?.prizeDetails }}</h3>
                            <p class="prize-value">Prize Value: {{ selectedEvent?.prizeValue | currency:'PHP':'symbol'
                                }}</p>
                            <p class="entries-info">Max Entries: {{ selectedEvent?.maxEntries }} ({{
                                selectedEvent?.maxEntriesPerUser }} per user)</p>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-md-6">
                            <div class="date-info">
                                <i class="fas fa-calendar-alt"></i>
                                <span>Entry Open: {{ selectedEvent?.entryOpenDate | date: 'MMM d, y, h:mm a' }}</span>
                            </div>
                            <div class="date-info">
                                <i class="fas fa-calendar-times"></i>
                                <span>Entry Close: {{ selectedEvent?.entryCloseDate | date: 'MMM d, y, h:mm a' }}</span>
                            </div>
                            <div class="date-info">
                                <i class="fas fa-gift"></i>
                                <span>Prize Draw: {{ selectedEvent?.prizeDrawDate | date: 'MMM d, y, h:mm a' }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="entries" class="form-label">Number of Entries:</label>
                                <input *ngIf="selectedEvent?.entriesLeft != 0" type="number" id="entries" [min]="1"
                                    [max]="maxEntries" class="form-control vip-input" formControlName="entries"
                                    (input)="updateTotalPrice()">
                                <p *ngIf="selectedEvent?.entriesLeft == 0" class="text-danger">All entries for this
                                    event have been sold.</p>
                                <div *ngIf="subscriptionForm.controls.entries.invalid && subscriptionForm.controls.entries.touched"
                                    class="text-danger mt-2">
                                    Entries must be between 1 and {{ maxEntries }}.
                                </div>
                            </div>
                            <div class="total-price mt-3">
                                <span class="label">Total Price:</span>
                                <span class="price">{{ totalPrice | currency:'PHP':'symbol' }}</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary vip-btn-secondary"
                    data-bs-dismiss="modal">Cancel</button>
                    <button 
                    type="button" 
                    class="btn btn-primary vip-btn-primary" 
                    [disabled]="isButtonDisabled()" 
                    (click)="confirmBuySubscription()"
                    [title]="getButtonTooltip()">
                    {{ getButtonText() }}
                  </button>
            </div>
        </div>
    </div>
</div>