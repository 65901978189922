import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user.model';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    user = new User();
    isDropdownOpen = false;
    public isLoggedIn = false;
    public userProfile: ExtendedKeycloakProfile | null = null;

    constructor(private readonly keycloak: KeycloakService,
        private loginService: LoginService) { }

    public async ngOnInit() {
        this.isLoggedIn = await this.keycloak.isLoggedIn();

        if (this.isLoggedIn) {
            this.userProfile  = await this.keycloak.loadUserProfile() as ExtendedKeycloakProfile;
            this.user.authStatus = 'AUTH';
            this.user.name = this.userProfile.firstName || "";
            this.user.email = this.userProfile.email || "";
            window.sessionStorage.setItem("userdetails", JSON.stringify(this.user));
            this.user.picture = 
    (this.userProfile && 
     this.userProfile['attributes'] && 
     this.userProfile['attributes']['picture']) || "../assets/images/no_profile.jpeg";

            this.loginService.user$.next(this.user);
        }
    }

    public login() {
        this.keycloak.login();
    }
    register() {
        this, this.keycloak.register();
    }
    public logout() {
        let redirectURI: string = window.location.origin + window.location.pathname;
        this.keycloak.logout(redirectURI);
        window.sessionStorage.removeItem("userdetails");
        this.isDropdownOpen = false;
    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }
}

export interface ExtendedKeycloakProfile extends KeycloakProfile {
    attributes: {
        picture: string;
    };
}
