import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EventsPageResponse } from 'src/app/model/eventspageresponse.model';
import { EventSearchCriteria } from 'src/app/model/eventssearchcriteria.model';
import * as moment from 'moment';
import { EventService } from 'src/app/services/events/events.service';
import { Modal } from 'bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EventRequest } from 'src/app/model/eventrequest.model';
import { PaymentRequest } from 'src/app/model/paymentrequest.model';
import { EventResponse } from 'src/app/model/eventresponse.model';
import { LoginService } from 'src/app/services/login/login.service';
import { KeycloakService } from 'keycloak-angular';
import { PaymentService } from 'src/app/services/payments/payments.service';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    @ViewChild('addModal', { static: true }) addModalElement: any;
    addModal: Modal | undefined;

    @ViewChild('updateModal', { static: true }) updateModalElement: any;
    updateModal: Modal | undefined;

    @ViewChild('buySubscriptionModal', { static: true }) buySubscriptionModal: any;
    buySubscription: Modal | undefined;

    @ViewChild('hook', { static: true }) hook: ElementRef;

    subscriptionForm = new FormGroup({
        entries: new FormControl(1, [
            Validators.required,
            Validators.min(1)
        ])
    });

    user: any;
    selectedEvent!: EventRequest;
    events: EventResponse[] = [];
    eventsPageResponse!: EventsPageResponse;
    currentPage = 0;
    pageSize = 3;
    totalPrice: number = 0;
    loading: boolean = false;
    timerSubscription: Subscription | undefined;

    constructor(private eventService: EventService,
        private paymentService: PaymentService,
        private fb: FormBuilder,
        private loginService: LoginService,
        private readonly keycloak: KeycloakService) {
        this.hook = new ElementRef('');
    }

    get maxEntries() {
        return Math.min(this.selectedEvent?.maxEntriesPerUser, this.selectedEvent?.entriesLeft);
    }

    ngAfterViewInit(): void {
        this.loginService.user$.subscribe((user) => {
            this.user = user;
        });
        this.buySubscription = new Modal(this.buySubscriptionModal.nativeElement);
    }

    updateTotalPrice(): void {
        const entriesBought = this.subscriptionForm.get('entries')?.value || 0;
        this.totalPrice = entriesBought * this.selectedEvent.entryPrice;
    }

    ngOnInit(): void {
        this.loadEvents(this.currentPage, this.pageSize);
        this.timerSubscription = interval(1000).subscribe(() => {
            // This will trigger change detection and update the time remaining
            this.events = [...this.events];
          });
    }

    onBuySubscription(event: any) {
        this.selectedEvent = { ...event }
        const maxEntries = this.maxEntries;
        this.subscriptionForm.get('entries')?.setValidators([
            Validators.required,
            Validators.min(1),
            Validators.max(maxEntries)
        ]);
        this.subscriptionForm.get('entries')?.updateValueAndValidity();

        // Set initial value and show the modal
        this.subscriptionForm.patchValue({ entries: 1 });
        this.updateTotalPrice();
        this.buySubscription?.show();
    }

    loadEvents(page: number, size: number): void {
        this.loading = true;
        const criteria: EventSearchCriteria = {hero: true,
            page: page, size: size
        };

        this.eventService.fetchEvents(criteria).subscribe({
            next: (response) => {
                if (page === 0) {
                    this.events = response.content;
                } else {
                    this.events = [...this.events, ...response.content];
                }

                this.eventsPageResponse = { ...response };
                this.currentPage = page;
                this.loading = false;
            },
            error: (err) => {
                console.error('Error fetching events:', err);
                this.loading = false;
            }
        });
    }

    async confirmBuySubscription(): Promise<void> {
        if (this.subscriptionForm.valid) {
            const isLoggedIn = await this.keycloak.isLoggedIn();
            if (!isLoggedIn) {
                this.keycloak.login();
            } else {
                const entriesBought = this.subscriptionForm.get('entries')?.value || 0;

                const paymentRequest: PaymentRequest = {
                    eventBid: this.selectedEvent.bid,
                    entriesBought: entriesBought,
                    amount: this.totalPrice,
                    tickets: entriesBought,
                    itemDesc: this.selectedEvent.prizeDetails
                };

                this.paymentService.buySubscription(paymentRequest).subscribe({
                    next: (response) => {
                        if (response && response.data && response.data.attributes && response.data.attributes.checkout_url) {
                            window.location.href = response.data.attributes.checkout_url;
                        }
                    },
                    error: (err) => {
                        alert('Error buying subscription. Please try again later. ' + err.error.errorMessage);
                    }
                });
            }
        } else {
            this.subscriptionForm.markAllAsTouched();
        }
    }

    onScroll(): void {
        if (this.currentPage < this.eventsPageResponse.totalPages - 1 && !this.loading) {
            this.loadEvents(this.currentPage + 1, this.pageSize);
        }
    }

    dateValidator(compareField: string) {
        return (control: { value: string }) => {
            const compareDate = this.subscriptionForm?.get(compareField)?.value;
            if (control.value && compareDate && new Date(control.value) <= new Date(compareDate)) {
                return { invalidDate: true };
            }
            return null;
        };
    }

    isEventClosed(closeDate: string): boolean {
        return new Date(closeDate) < new Date();
      }
    
      isUrgent(closeDate: string): boolean {
        const timeLeft = new Date(closeDate).getTime() - new Date().getTime();
        const daysLeft = timeLeft / (1000 * 60 * 60 * 24);
        return daysLeft <= 3 && daysLeft > 0;
      }
    
      getTimeRemaining(closeDate: string): string {
        const total = new Date(closeDate).getTime() - new Date().getTime();
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
    
        if (days > 3) {
          return `${days} days`;
        } else {
          return `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      }

      isButtonDisabled(): boolean {
        return this.subscriptionForm.invalid ||
            (this.selectedEvent?.entriesLeft === 0) ||
            this.isDatePassed(this.selectedEvent?.entryCloseDate);
    }

    getButtonText(): string {
        if (this.subscriptionForm.invalid) {
            return 'Please Fill All Required Fields';
        }
        if (this.selectedEvent?.entriesLeft === 0) {
            return 'Sold Out';
        }
        if (this.isDatePassed(this.selectedEvent?.entryCloseDate)) {
            return 'Event Closed';
        }
        return 'Confirm Purchase';
    }

    getButtonTooltip(): string {
        if (this.subscriptionForm.invalid) {
            return 'Please fill all required fields before confirming your purchase.';
        }
        if (this.selectedEvent?.entriesLeft === 0) {
            return 'This event is sold out.';
        }
        if (this.isDatePassed(this.selectedEvent?.entryCloseDate)) {
            return 'This event has already closed.';
        }
        return 'Click to confirm your purchase.';
    }

    parseDate(dateString: string | Date): Date {
        if (dateString instanceof Date) {
            return dateString;
        }
        return new Date(dateString);
    }

    isDatePassed(dateString: string | Date | undefined): boolean {
        if (!dateString) {
            return false;
        }
        const date = this.parseDate(dateString);
        return date < new Date();
    }

    isPrizeDrawPending(event: any): boolean {
        const now = new Date();
        const entryCloseDate = new Date(event.entryCloseDate);
        const prizeDrawDate = new Date(event.prizeDrawDate);
        return now > entryCloseDate && now < prizeDrawDate;
      }
}
