import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { EventsComponent } from './components/events/events.component';
import { AuthKeyClockGuard } from './routeguards/auth.route';
import { HomeComponent } from './home/home.component';
import { OrderHistoryComponent } from './order-history/order-history.component';

const routes: Routes = [
    { path: '', component: HomeComponent},
    { path: 'events', component: EventsComponent},
    { path: 'orders', component: OrderHistoryComponent, canActivate: [AuthKeyClockGuard] }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
