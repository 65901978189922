import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventSearchCriteria } from 'src/app/model/eventssearchcriteria.model';
import { EventsPageResponse } from 'src/app/model/eventspageresponse.model';
import { EventRequest } from 'src/app/model/eventrequest.model';

@Injectable({
    providedIn: 'root'
})
export class EventService {
   
    private apiUrl = `${environment.rooturl}events/api/`;

    constructor(private http: HttpClient) { }

    fetchEvents(criteria: EventSearchCriteria): Observable<EventsPageResponse> {
        return this.http.post<EventsPageResponse>(this.apiUrl + 'events', criteria);
    }

    createEvent(event: EventRequest): Observable<any> {
        return this.http.post<any>(this.apiUrl + 'event', event, { withCredentials: true });
    }

    updateEvent(event: EventRequest): Observable<any> {
        const url = `${this.apiUrl}event/update?bid=${event.bid}`;
        return this.http.put(url, event, { withCredentials: true });
    }

    deleteEvent(bid: string): Observable<any> {
        const url = `${this.apiUrl}event/delete?bid=${bid}`;
        return this.http.put(url, { withCredentials: true });
    }

}
