import {APP_INITIALIZER,NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { EventsComponent } from './components/events/events.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { HomeComponent } from './home/home.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { environment } from 'src/environments/environment';
import { FooterComponent } from './footer/footer.component';


function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
      keycloak.init({
        config: {
          url: environment.KeycloakUrl,
          realm: 'yana',
          clientId: 'yana-ui',
        },
        initOptions: {
          onLoad: 'check-sso',
          pkceMethod: 'S256',
          redirectUri: window.location.origin + window.location.pathname,
          checkLoginIframe: false,
          enableLogging: true,
          useNonce: false,
        },loadUserProfileAtStartUp: false
    });
  }

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    EventsComponent,
    HomeComponent,
    OrderHistoryComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    KeycloakAngularModule,
    HttpClientModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
  ],
  providers: [
    {
        provide: APP_INITIALIZER,
        useFactory: initializeKeycloak,
        multi: true,
        deps: [KeycloakService],
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
