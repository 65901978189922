import { Component, OnInit, HostListener } from '@angular/core';
import { LoginService } from '../services/login/login.service';
import { OrderSearchCriteria } from '../model/OrderSearchCriteria.model';
import { PaymentService } from '../services/payments/payments.service';
import { EventSearchCriteria } from '../model/eventssearchcriteria.model';
import { EventService } from '../services/events/events.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {
    currentPage = 0;
    pageSize = 3;
    eventPage = 0;
    eventPageSize = 10;
    orderHistory: any;
    loading: boolean = false;
    orders: any[] = [];
    events: any[] = [];
    yearsList: number[] = [];
    selectedYear: string = '';
    selectedEventId: string = '';
    eventsLoading: boolean = false;
    hasMoreEvents: boolean = true;

  constructor(private loginService: LoginService,
              private eventService: EventService,
              private paymentService: PaymentService) { }

  user: any;

  ngOnInit(): void {
    this.loginService.user$.subscribe((user) => {
        this.user = user;
    });
    this.loadEvents(this.eventPage, this.eventPageSize); // Load event list for filter
    this.fetchYearsList(); 
    this.loadOrderHistory(this.currentPage, this.pageSize);
  }

  loadEvents(page: number, size: number): void {
    if (this.eventsLoading || !this.hasMoreEvents) return;
    
    this.eventsLoading = true;
    const criteria: EventSearchCriteria = {page: page, size: size };

    this.eventService.fetchEvents(criteria).subscribe({
        next: (response) => {
            if (page === 0) {
                this.events = response.content;
            } else {
                this.events = [...this.events, ...response.content];
            }

            this.hasMoreEvents = response.totalPages > page + 1;
            this.eventPage = page;
            this.eventsLoading = false;
        },
        error: (err) => {
            console.error('Error fetching events:', err);
            this.eventsLoading = false;
        }
    });
  }

  fetchYearsList(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 10; year--) {
      this.yearsList.push(year);
    }
  }

  onScroll(): void {
    if (this.currentPage < this.orderHistory.totalPages - 1 && !this.loading) {
        this.loadOrderHistory(this.currentPage + 1, this.pageSize);
    }
  }

  loadOrderHistory(page: number, size: number): void {
    const orderSearchCriteria: OrderSearchCriteria = {
        eventId: this.selectedEventId,
        year: this.selectedYear,
        page: page,
        size: size
    };
    this.loading = true;
    this.paymentService.orderHistory(orderSearchCriteria).subscribe({
        next: (response) => {
            if (page === 0) {
                this.orders = response.content;
            } else {
                this.orders = [...this.orders, ...response.content];
            }

            this.orderHistory = { ...response };
            this.currentPage = page;
            this.loading = false;
        },
        error: (err) => {
            console.error('Error fetching order history:', err);
            this.loading = false;
        }
    });
  }

  onFilterChange(): void {
    this.currentPage = 0; // Reset to first page on filter change
    this.orders = []; // Clear current orders
    this.loadOrderHistory(this.currentPage, this.pageSize); // Load filtered orders
  }

  @HostListener('scroll', ['$event'])
  onDropdownScroll(event: any): void {
    const scrollPosition = event.target.scrollTop + event.target.clientHeight;
    const threshold = event.target.scrollHeight - 100;

    if (scrollPosition > threshold && !this.eventsLoading && this.hasMoreEvents) {
        this.loadEvents(this.eventPage + 1, this.eventPageSize);
    }
  }
}
